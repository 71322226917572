// @ts-nocheck
import { Box, Button, Stack, Typography } from '@mui/material';
import CheckCircle from '../../../assets/check_circle.png';
import EastIcon from '@mui/icons-material/East';
import React, { useEffect } from 'react';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';

const SharingConcernTerminationScreen = () => {
  const { releaseOfferLetter, setScreenSource, screenSource } =
    useFastrackPipeline();

  useEffect(() => {
    setScreenSource('screen_2b');
    return () => {};
  }, []);

  const handleReadyToEnroll = () => {
    releaseOfferLetter({ source: screenSource });
  };

  return (
    <Stack
      spacing={3}
      alignItems='center'
      textAlign='center'
      mb='1rem'
    >
      <Stack
        alignItems='center'
        sx={{
          height: 'fit-content',
        }}
      >
        <img
          style={{
            width: '90px',
          }}
          src={CheckCircle}
          alt='trophy'
        />
      </Stack>

      <Typography
        variant='h3'
        component='h3'
        sx={{
          color: '#1976d2',
          fontFamily: 'Inter',
          fontWeight: 'bolder',
          fontSize: {
            xs: '1.7rem',
            sm: '2.1rem',
            md: '2.1rem',
            lg: '2.1rem',
          },
          width: 'fit-content',
          textAlign: 'center',
          textWrap: 'stable',
        }}
      >
        Thanks for sharing your concern with us
      </Typography>

      <Typography
        variant='body1'
        component='p'
        sx={{
          color: '#1976d2',
          textAlign: 'center',
          fontFamily: 'Inter',
          fontWeight: 'normal',
          fontSize: {
            xs: '1rem',
            sm: '1.15rem',
            md: '1.2rem',
            lg: '1.2rem',
          },
          textWrap: 'pretty',
        }}
      >
        Our team will contact you shortly to address your concern in the best
        way possible
      </Typography>

      <Stack
        spacing={4}
        alignItems='center'
        sx={{
          background: '#e3f2fd',
          borderRadius: '0.8rem',
          padding: '1rem',
          width: {
            xs: '100%',
            sm: '95%',
            md: '90%',
            lg: '90%',
          },
        }}
      >
        <Typography
          variant='body1'
          component='p'
          sx={{
            textAlign: 'center',
            fontFamily: 'Inter',
            width: '90%',
            fontWeight: 450,
            textWrap: 'stable',
          }}
        >
          Only 5% of eligible candidates receive an offer letter via fast-track
          test. Missing the chance to secure your seat will need you to take a
          screening interview which has a lower selection rate. It might be
          worth reconsidering securing your seat now.
        </Typography>

        <Button
          variant='contained'
          sx={{
            fontFamily: 'Inter',
            textTransform: 'none',
            padding: '0.8rem 1.1rem',
            background:
              'linear-gradient(to right, rgb(0, 154, 240), rgb(251, 199, 4))',
          }}
          onClick={handleReadyToEnroll}
          endIcon={<EastIcon />}
        >
          I'm ready to enroll
        </Button>
      </Stack>
    </Stack>
  );
};

export default SharingConcernTerminationScreen;
