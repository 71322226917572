// @ts-nocheck
import {
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import TargetLogo from '../../../assets/target.png';
import React from 'react';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';

const GreatFitForCourse = ({ time, handleReadyToEnroll }) => {
  const { screenSource } = useFastrackPipeline();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = () => {
    handleReadyToEnroll({ source: screenSource });
  };

  return (
    <Stack
      alignItems='center'
      justifyContent='space-between'
      sx={{
        width: {
          xs: '20rem',
          sm: '22rem',
          md: '23rem',
          lg: '25rem',
          xl: '32%',
        },
        height: '24rem',
        border: '3px solid #e3f2fd',
        borderRadius: '0.8rem',
        padding: '1rem',
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 1px 5px 0px',
      }}
    >
      <img
        style={{
          width: '85px',
        }}
        src={TargetLogo}
        alt='target-logo'
      />

      <Typography
        variant='h4'
        component='h4'
        sx={{
          fontFamily: 'Inter',
          fontWeight: 'bolder',
          paddingTop: 0,
          lineHeight: 'normal',
          textAlign: 'center',
        }}
      >
        You are a great fit for our course.
      </Typography>

      <Typography
        variant='body1'
        component='p'
        sx={{
          fontFamily: 'Inter',
          fontWeight: 500,
          fontSize: '1.1rem',
          textAlign: 'center',
        }}
      >
        An offer letter and a chance to secure your seat awaits.
      </Typography>

      <Button
        variant='contained'
        endIcon={<ArrowRightAltIcon fontSize='large' />}
        sx={{
          background: '#1976d2',
          color: 'white',
          fontFamily: 'Inter',
          '&:hover': {
            background: '#1976d2',
          },
          padding: '0.7rem 0.5rem',
          width: '80%',
          alignSelf: 'center',
          boxShadow: 'none',
        }}
        onClick={handleClick}
      >
        Release Offer Letter
      </Button>
    </Stack>
  );
};

export default GreatFitForCourse;
