// @ts-nocheck
import { Box, Divider, Stack, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react';

const BasicModePaymentCard = () => {
  return (
    <Stack
      spacing={1.5}
      sx={{
        width: {
          xs: '20rem',
          sm: '22rem',
          md: '23rem',
          lg: '36%',
          xl: '32%',
        },
        height: '27rem',
        border: '1px solid #bdbdbd',
        borderRadius: '0.5rem',
        padding: '1.3rem',
        display: 'flex',
      }}
    >
      <Typography
        variant='body1'
        component='p'
        sx={{
          fontFamily: 'Inter',
          fontWeight: 'bold',
          fontSize: '1.5rem',
        }}
      >
        Basic Mode
      </Typography>

      <Typography
        variant='body2'
        component='p'
        sx={{
          fontFamily: 'Inter',
          fontWeight: 500,
          fontSize: '0.8rem',
        }}
      >
        Enter the waitlist for the interview, where your spot will be secured
        when availability opens up.
      </Typography>

      <Box
        display='flex'
        gap='8px'
      >
        <Typography
          style={{
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '1.5rem',
          }}
        >
          &#8377;
        </Typography>
        <Typography
          variant='h2'
          component='h2'
          sx={{
            fontFamily: 'Inter',
            fontWeight: 'bolder',
            paddingTop: 0,
            lineHeight: 'normal',
          }}
        >
          10,000
        </Typography>
      </Box>

      <Divider
        orientation='horizontal'
        flexItem
        sx={{ borderBottomWidth: 1.5 }}
      />

      <Stack
        spacing={1.5}
        pt='0.6rem'
      >
        <Typography
          variant='body1'
          component='p'
          sx={{
            fontFamily: 'Inter',
            display: 'flex',
            gap: '0.8rem',
            fontWeight: 600,
          }}
        >
          <CancelIcon color='error' /> Exclusive coupons
        </Typography>

        <Typography
          variant='body1'
          component='p'
          sx={{
            fontFamily: 'Inter',
            display: 'flex',
            gap: '0.8rem',
            fontWeight: 600,
          }}
        >
          <CancelIcon color='error' /> Access to language bible
        </Typography>
      </Stack>
    </Stack>
  );
};

export default BasicModePaymentCard;
