// @ts-nocheck
import {
  Alert,
  Box,
  Grid,
  Snackbar,
  SnackbarContent,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { LoadingButton } from '@material-ui/lab';
import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import FastrackOfferLetterCountdown from '../FastrackOfferLetterCountdown';
import EfPipelineOfferLetter from '../../ScreeningTest/EfPipelineOfferLetter';
import OfferLetterModal from './OfferLetterModal';
import ConnectingScreen from './ConnectingScreen';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';
import { useEfPipeline } from 'src/layouts/EfPipelineContext/EfPipelineContext';
import FastrackOfferLetterExitPopup from '../FastrackOfferLetterExitPopup';

const LargeScreenOfferLetter = ({ requestExtension, requestCallback }) => {
  const {
    setShowFastrackOfferLetter,
    setMessage,
    olMessage: message,
  } = useEfPipeline();

  const {
    showConnectingScreen,
    setShowConnectingScreen,
    screenType,
    offerLetter,
    activeOLStatus,
    setExpiryExtensionMessage,
    expiryExtensionMessage,
    extensionRequestCount,
  } = useFastrackPipeline();
  const theme = useTheme();
  const [showOfferLetter, setShowOfferLetter] = useState(false);
  const { link } = offerLetter;
  const expiryDate = new Date(offerLetter?.expires_at).getTime();
  const [time, setTime] = useState(expiryDate - new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(expiryDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [expiryDate]);

  const reset = () => {
    setShowFastrackOfferLetter(false);
    setMessage(
      'You need an invitation to apply to HeyCoach programs. If you are an existing learner, please check your login email id.'
    );
  };

  if (time <= 0) {
    reset();
    return;
  }

  return (
    <>
      <Snackbar
        open={expiryExtensionMessage.length > 0 && extensionRequestCount == 1}
        autoHideDuration={20000}
        onClose={() => setExpiryExtensionMessage('')}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{
          bottom: '20vh !important',
          left: '50% !important',
          right: 'auto !important',
          transform: 'translateX(-50%)',
        }}
      >
        <Alert
          onClose={() => setExpiryExtensionMessage('')}
          severity='warning'
          variant='standard'
          sx={{ width: '100%', backgroundColor: '#ffecb3' }}
        >
          {expiryExtensionMessage}
        </Alert>
      </Snackbar>

      <FastrackOfferLetterExitPopup setShowOfferLetter={setShowOfferLetter} />

      <OfferLetterModal
        {...{ showOfferLetter, setShowOfferLetter, offerLetter }}
      />

      <Grid
        container
        item
        rowSpacing={2}
        height='inherit'
        justifyContent='space-between'
        sx={{
          flexWrap: 'wrap',
          paddingTop: '0.5rem',
          paddingLeft: 'clamp(2rem, 3rem, 4.5rem)',
          paddingRight: 'clamp(2rem, 3rem, 4.5rem)',
        }}
      >
        {showConnectingScreen && (
          <ConnectingScreen setShowConnectingScreen={setShowConnectingScreen} />
        )}

        {!showConnectingScreen && (
          <>
            <Grid
              item
              xs={7.6}
            >
              <Stack spacing={1.3}>
                <Stack
                  direction='row'
                  spacing={1}
                >
                  <Box
                    sx={{
                      fontSize: 'clamp(1.2rem, 1.6rem, 1.7rem)',
                      alignSelf: 'center',
                    }}
                  >
                    🌟
                  </Box>

                  <Typography
                    variant='body1'
                    component='p'
                    sx={{
                      color: '#1976d2',
                      fontFamily: 'Inter',
                      fontWeight: 'bolder',
                      fontSize: 'clamp(1.8rem, 2.1rem, 2.4rem)',
                      width: 'fit-content',
                    }}
                  >
                    Congratulations, You've passed!
                  </Typography>
                  <Box
                    sx={{
                      fontSize: 'clamp(1.2rem, 1.6rem, 1.7rem)',
                      alignSelf: 'center',
                    }}
                  >
                    🌟
                  </Box>
                </Stack>

                <Typography
                  variant='body1'
                  component='p'
                  sx={{
                    textWrap: 'balance',
                    fontFamily: 'Inter',
                    fontWeight: 600,
                    fontSize: 'clamp(1.4rem, 1.5rem, 1.8rem)',
                  }}
                >
                  <span
                    style={{
                      backgroundImage:
                        'linear-gradient(to left, rgb(0, 154, 240), rgb(251, 199, 4) 120%)',
                      '-webkit-text-fill-color': 'transparent',
                      backgroundClip: 'text',
                    }}
                  >
                    You're among the top candidates!
                  </span>
                </Typography>

                <Stack spacing={1.3}>
                  <Typography
                    variant='caption'
                    component='p'
                    textAlign='justify'
                    sx={{
                      textWrap: 'wrap',
                      fontFamily: 'Inter',
                      fontWeight: 400,
                      fontSize: 'clamp(1.1rem, 1.25rem, 1.4rem)',
                    }}
                  >
                    With a selection rate of just 5%, you've made it through
                    several layers of evaluations.
                  </Typography>

                  <Typography
                    variant='caption'
                    component='p'
                    textAlign='justify'
                    sx={{
                      textWrap: 'wrap',
                      fontFamily: 'Inter',
                      fontWeight: 400,
                      fontSize: 'clamp(1.1rem, 1.25rem, 1.4rem)',
                    }}
                  >
                    Our program is designed to push you to the forefront of the
                    tech industry. By joining, you'll gain the skills,
                    knowledge, and network to achieve extraordinary heights.
                    <br />
                    The future is yours – let's make it remarkable together!
                  </Typography>

                  <Typography
                    variant='caption'
                    component='p'
                    textAlign='justify'
                    sx={{
                      textWrap: 'wrap',
                      fontFamily: 'Inter',
                      fontWeight: 400,
                      fontSize: 'clamp(1.1rem, 1.25rem, 1.4rem)',
                    }}
                  >
                    Click the button to view your offer letter. It includes
                    details about the program and a payment link to secure your
                    seat.
                  </Typography>
                </Stack>
              </Stack>
            </Grid>

            <Grid
              item
              xs={4}
              md={4}
              lg={3.5}
              height='80%'
              display='flex'
              direction='column'
              justifyContent='center'
            >
              <Stack
                alignItems='center'
                sx={{
                  position: 'relative',
                  height: '80%',
                }}
              >
                <Box
                  height='80%'
                  width='85%'
                  sx={{
                    filter: 'blur(5px)',
                    position: 'relative',
                  }}
                >
                  <EfPipelineOfferLetter {...offerLetter} />
                </Box>
                <Button
                  variant='contained'
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '150px',
                    padding: '0.5rem 0.5rem',
                    fontSize: 'clamp(0.5rem, 0.8rem, 1rem)',
                  }}
                  onClick={(e) => setShowOfferLetter(true)}
                >
                  <RemoveRedEyeIcon fontSize='small' /> &nbsp; View Offer Letter
                </Button>
              </Stack>

              <Box
                sx={{
                  display: 'flex',
                  alignSelf: 'center',
                  padding: '0.2em 5px',
                  border: '1px solid #1976d2',
                  width: 'fit-content',
                }}
              >
                <Typography
                  variant='body1'
                  component='p'
                  sx={{
                    fontSize: 'clamp(0.9rem, 0.9rem, 1.4rem)',
                    color: '#1976d2',
                    fontFamily: 'Inter',
                    ':hover': {
                      cursor: 'pointer',
                    },
                    textDecorationSkipInk: 'none',
                  }}
                  onClick={(e) => {
                    setShowConnectingScreen(true);
                    requestCallback();
                  }}
                >
                  Need more info? Request a callback
                </Typography>
              </Box>
            </Grid>
          </>
        )}

        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'flex-end',
            paddingTop: 0,
          }}
        >
          <Stack
            spacing={4}
            direction='row'
            alignItems='center'
            justifyContent='center'
            sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '90%',
                xl: '80%',
              },
              height: 'fit-content',
              padding: 'clamp(0.5rem, 1rem, 1rem)',
              borderRadius: '36px',
              background: '#e3f2fd',
            }}
          >
            <Stack
              direction='row'
              spacing={2}
              alignItems='center'
            >
              <Typography
                variant='h6'
                component='h6'
                sx={{
                  fontFamily: 'Inter',
                  fontWeight: 500,
                }}
              >
                The offer letter expires in
              </Typography>
              <FastrackOfferLetterCountdown
                time={time}
                style={{ background: '#ffeb3b' }}
              />
            </Stack>

            <Stack
              direction='row'
              spacing={1}
            >
              {extensionRequestCount <= 1 && screenType === 'screen_1' && (
                <Button
                  variant='contained'
                  sx={{
                    padding: '0.8rem 1.3rem',
                    background: 'white',
                    color: '#1976d2',
                    ':hover': {
                      background: 'white',
                      color: '#1976d2',
                    },
                  }}
                  onClick={(e) => {
                    requestExtension();
                  }}
                  startIcon={
                    <AddCircleIcon
                      fontSize='small'
                      sx={{ color: '#1976d2' }}
                    />
                  }
                >
                  I need more time
                </Button>
              )}

              <Tooltip title={activeOLStatus === 'processing' ? message : ''}>
                <span>
                  <LoadingButton
                    loading={activeOLStatus === 'processing'}
                    disabled={activeOLStatus === 'processing'}
                    variant='contained'
                    href={link}
                    sx={{
                      padding: '0.8rem 1.3rem',
                      background:
                        'linear-gradient(to left, rgb(0, 154, 240), rgb(251, 199, 4) 120%)',
                    }}
                  >
                    Secure your seat
                  </LoadingButton>
                </span>
              </Tooltip>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default LargeScreenOfferLetter;
