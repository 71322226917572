// @ts-nocheck
import axios from 'axios';
import React, { useEffect, useRef } from 'react';
import { BASE_URL, getActiveLetter, getHeaders } from 'src/apis/urls';
import { useEfPipeline } from 'src/layouts/EfPipelineContext/EfPipelineContext';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';
import SuccessScreen from '../eligibility-forms/SuccessScreen';
import LoadingScreen from 'src/pages/LoadingScreen';
import Screen4 from './FastrackOLScreen4/Screen4';
import Screen1 from './FastrackOLScreen1/Screen1';
import Screen3 from './FastrackOLScreen3/Screen3';

// https://developer.phonepe.com/v1/reference/check-status-api-1/#:~:text=If%20the%20payment%20status%20is%20Pending%2C%20then%20Check%20Status%20API%20should%20be%20called%20in%20the%20following%20interval%3A
const retryIntervals = [
  20000, // First check at 20-25 seconds
  3000,
  3000,
  3000,
  3000,
  3000,
  3000,
  3000,
  3000,
  3000, // Every 3 seconds for the next 30 seconds
  6000,
  6000,
  6000,
  6000,
  6000,
  6000,
  6000,
  6000,
  6000,
  6000, // Every 6 seconds for the next 60 seconds
  10000,
  10000,
  10000,
  10000,
  10000,
  10000,
  10000,
  10000,
  10000,
  10000, // Every 10 seconds for the next 60 seconds
  30000,
  30000,
  30000,
  30000,
  30000,
  30000,
  30000,
  30000,
  30000,
  30000, // Every 30 seconds for the next 60 seconds
  60000,
  60000,
  60000,
  60000,
  60000,
  60000,
  60000,
  60000,
  60000,
  60000, // Every 1 minute until timeout
];

const FastrackOfferLetterLayout = () => {
  const {
    setShowFastrackOfferLetter,
    setMessage,
    showFakeResponseAnalyzerForScreeningTest,
    setShowFakeResponseAnalayerForScreeningTest,
  } = useEfPipeline();

  const {
    loading,
    setLoading,
    olMessage,
    setOLMessage,
    activeOLStatus,
    setActiveOLStatus,
    offerLetter,
    setOfferLetter,
    setExtensionRequestCount,
    setShowOfferLetterInfoPopup,
    screenType,
    setScreenType,
  } = useFastrackPipeline();
  const pollingStartTime = useRef(-1);
  const pollingIntervalIndex = useRef(-1);
  const maxTimeout = 5 * 60 * 1000; // 5 minutes in milliseconds;

  useEffect(() => {
    if (!showFakeResponseAnalyzerForScreeningTest) {
      fetchActiveOfferLetter();
    }
  }, [showFakeResponseAnalyzerForScreeningTest]);

  const reset = () => {
    setShowFastrackOfferLetter(false);
    setMessage(
      'You need an invitation to apply to HeyCoach programs. If you are an existing learner, please check your login email id.'
    );
  };

  const fetchActiveOfferLetter = async () => {
    setLoading(true);
    try {
      const res = await axios.get(getActiveLetter(), {
        headers: getHeaders(),
      });

      const { status = null, message } = res.data;
      if (!status) {
        reset();
        return;
      }

      setActiveOLStatus(status);
      setOLMessage(message);
      if (status !== 'success') {
        setOfferLetter({
          olId: res?.data?.offer_letter?.id,
          lname: res?.data?.lead_lname,
          fname: res?.data?.lead_fname,
          lead_owner: res.data.lead_owner,
          leadId: res?.data?.offer_letter?.lead_id,
          program: res?.data?.offer_letter?.response?.program,
          link: res?.data?.offer_letter?.response.payment_link,
          expires_at: res?.data?.offer_letter?.expires_at,
        });

        setScreenType(res?.data?.screen_type ?? 'v1');

        // TODO: Remove this line and uncomment the line above. This is only meant to be used for testing purpose.
        // setScreenType('v4');
        setShowOfferLetterInfoPopup(res.data?.show_ol_info_popup);
        setExtensionRequestCount(
          res.data.offer_letter.extend_expiry_request_count ?? 0
        );
      }

      if (status === 'processing') {
        if (pollingStartTime.current === -1) {
          pollingStartTime.current = Date.now();
        }
        pollingIntervalIndex.current += 1;
        pollForTransactionStatus();
      }
    } catch (e) {
      console.log(e);
      setShowFastrackOfferLetter(false);
      setMessage(e?.response?.data?.message ?? 'Oops! Something went wrong.');
    } finally {
      setLoading(false);
    }
  };

  const pollForTransactionStatus = () => {
    let totalTimeElapsed = Date.now() - pollingStartTime.current;

    if (
      pollingIntervalIndex.current >= retryIntervals.length ||
      totalTimeElapsed >= maxTimeout
    ) {
      console.log('Max timeout reached or all retries done.');
      setMessage(
        "Couldn't get an update on your payment. Please contact your POC for more information."
      );
      return;
    }

    const delay = retryIntervals[pollingIntervalIndex.current];
    setTimeout(() => fetchActiveOfferLetter(), delay);
  };

  const getComponent = () => {
    if (activeOLStatus === 'success') {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px',
            paddingBottom: '3rem',
            paddingTop: '2rem',
          }}
        >
          <SuccessScreen successMessage={olMessage} />;
        </div>
      );
    }

    if (screenType === 'v1' || screenType === 'v2') {
      return <Screen1 />;
    }

    if (screenType === 'v3') {
      return <Screen3 />;
    }

    if (screenType === 'v4') {
      return <Screen4 />;
    }

    return <></>;
  };

  return (
    <>
      {loading && <LoadingScreen loading={loading} />}
      {getComponent()}
    </>
  );
};

export default FastrackOfferLetterLayout;
