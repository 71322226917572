// @ts-nocheck
import {
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import React, { useEffect, useState } from 'react';
import SharingConcernTerminationScreen from './SharingConcernTerminationScreen';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';
import axios from 'axios';
import { BASE_URL, getHeaders } from 'src/apis/urls';

const SelectConcernScreen = ({ setShowTerminalScreen, saveFlowData }) => {
  const { trackButtonClick, offerLetter, setScreenSource, screenSource } =
    useFastrackPipeline();
  const [otherReason, setOtherReason] = useState('');
  const [isOtherReason, setIsOtherReason] = useState(false);

  // sets the current screen source by default.
  useEffect(() => {
    setScreenSource('screen_2');
    return () => {};
  }, []);

  const sendAlert = async ({ concern }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/offer-letters/${offerLetter.olId}/concerns_alert`,
        {
          concern,
          lead_id: offerLetter.leadId,
          assignee: offerLetter.lead_owner,
          lead_name:
            (offerLetter.fname ?? '') +
            (offerLetter.lname ? ` ${offerLetter.lname}` : ''),
        },
        {
          headers: getHeaders(),
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const alertForOtherReason = () => {
    sendAlert({ concern: otherReason });
    saveFlowData({ screen: 'screen_2', response: { concern: otherReason } });
    trackButtonClick({
      source: 'screen_2a',
      eventName: 'submit_other_concern',
    });
    setScreenSource('screen_2b');
  };

  const alertForFeesHigh = () => {
    sendAlert({ concern: 'Fees are high' });
    saveFlowData({
      screen: 'screen_2',
      response: { concern: 'Fees are high' },
    });
    trackButtonClick({ source: screenSource, eventName: 'fees_are_high' });
  };

  const handleOtherClick = () => {
    if (!isOtherReason) {
      // This avoids creating an event on redundant clicks.
      trackButtonClick({ source: screenSource, eventName: 'other' });
    }
    setIsOtherReason(true);
  };

  const handleHighFeeClick = () => {
    alertForFeesHigh();
    setScreenSource('screen_4');
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setOtherReason(value);
  };

  return (
    <Stack
      spacing={3}
      alignItems='center'
      textAlign='center'
    >
      <Typography
        variant='h3'
        component='h3'
        sx={{
          color: '#1976d2',
          fontFamily: 'Inter',
          fontWeight: 'bolder',
          fontSize: {
            xs: '1.9rem',
            sm: '2.1rem',
            md: '2.1rem',
            lg: '2.1rem',
          },
          width: 'fit-content',
          textAlign: 'center',
          textWrap: 'stable',
        }}
      >
        Select the most relevant concern
      </Typography>

      <Typography
        variant='body1'
        component='p'
        sx={{
          textAlign: 'center',
          fontFamily: 'Inter',
          fontWeight: 'normal',
          fontSize: {
            xs: '1.1rem',
            sm: '1.2rem',
            md: '1.2rem',
            lg: '1.4rem',
          },
          textWrap: 'balance',
        }}
      >
        We understand that you have some concerns. You are one of the top few
        who have made it so far. Please select the most relevant concern.
      </Typography>

      <Stack
        spacing={4}
        sx={{
          maxWidth: '25rem',
          width: {
            xs: '100%',
            sm: '70%',
            md: '50%',
            lg: '50%',
          },
        }}
      >
        <Button
          sx={{
            color: '#1976d2',
            width: '100%',
            padding: '0.5rem 1rem',
            textTransform: 'none',
            fontFamily: 'Inter',
            fontSize: '1.2rem',
            fontWeight: 650,
            borderColor: '#1976d2',
          }}
          onClick={handleHighFeeClick}
          variant='outlined'
        >
          Fees are high
        </Button>

        <Button
          sx={{
            color: '#1976d2',
            width: '100%',
            padding: '0.5rem 1rem',
            textTransform: 'none',
            fontFamily: 'Inter',
            fontSize: '1.2rem',
            fontWeight: 650,
            borderColor: '#1976d2',
          }}
          onClick={handleOtherClick}
          variant='outlined'
        >
          Other
        </Button>
      </Stack>

      <TextField
        name='other_reason'
        value={otherReason}
        onChange={handleInputChange}
        variant='outlined'
        sx={{
          visibility: !isOtherReason && 'hidden',
          maxWidth: '25rem',
          width: {
            xs: '100%',
            sm: '70%',
            md: '50%',
            lg: '50%',
          },
          fontFamily: 'Inter',
          '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1976d2',
          },
          '& .MuiOutlinedInput-root': {
            display: 'flex',
            alignItems: 'flex-end',
          },
        }}
        placeholder='Please enter your concern'
        InputProps={{
          endAdornment: (
            <Button
              disabled={otherReason.trim().length === 0}
              variant='contained'
              startIcon={<EastIcon sx={{ justifySelf: 'center' }} />}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'flex-end',
                minWidth: '2rem',
                paddingRight: 0,
                marginLeft: 0,
                marginRight: 0,
                background: '#1976D2',
                '&:hover': {
                  background: '#1976D2',
                },
              }}
              onClick={(e) => {
                alertForOtherReason({ concern: otherReason });
              }}
            />
          ),
        }}
        multiline
        minRows={5}
      />
    </Stack>
  );
};

export default SelectConcernScreen;
