// @ts-nocheck
import {
  Typography,
  Stack,
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Fade,
  Grid,
  Slide,
  Divider,
} from '@mui/material';
import { styled } from '@mui/system';
import EastIcon from '@mui/icons-material/East';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';
import { useState } from 'react';
import { companyData } from './index.js';

const CompanyLogo = styled('img')(({ theme }) => ({
  width: '150px',
  maxWidth: '100%',
  height: '50px',
  objectFit: 'contain',
  cursor: 'pointer',
  '@media (max-width: 480px)': {
    maxWidth: '70px',
    height: '30px',
    objectFit: 'scale-down',
  },
}));

const CompanyRoleSelectionScreen = ({
  setFlowData,
  flowData,
  saveFlowData,
}) => {
  const { setScreenSource, trackButtonClick } = useFastrackPipeline();
  const theme = useTheme();
  const isBelowMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isBelow500 = useMediaQuery('(max-width: 500px)');
  const [selectedCompany, setSelectedCompany] = useState(null);

  const handleCompanyClick = (company) => {
    if (selectedCompany === company) return;
    setSelectedCompany(company);
  };

  const getSelectedCompanyLogos = () => {
    const selectedCompanies =
      flowData?.companies?.map((c, index) => c.name) ?? [];

    return selectedCompanies.map((c) => ({
      logo: companyData[c].logo,
      name: c,
    }));
  };

  const getCompanyRoles = (company) => {
    return (companyData[company]?.roles ?? [])?.map((r) => r.name) ?? [];
  };

  const updateRole = (value) => {
    setFlowData((prev) => {
      const temp = [...prev.companies];
      const index = temp.map((t) => t.name).indexOf(selectedCompany);
      if (index > -1 && !temp[index].roles.includes(value)) {
        temp[index].roles.push(value);
      } else if (index > -1) {
        temp[index].roles = temp[index].roles.filter((r) => r !== value);
      }
      return { ...prev, companies: temp };
    });
  };

  const handleNext = () => {
    trackButtonClick({ source: 'screen_6', eventName: 'next' });
    saveFlowData({
      screen: 'screen_6',
      response: {
        companies: flowData.companies,
      },
    });
    setScreenSource('screen_7');
  };

  const shouldDisableNext = () => {
    return flowData?.companies.filter((c) => c.roles.length > 0).length === 0;
  };

  const isSelectedRole = (value) => {
    const selectedCompanies = flowData.companies ?? [];
    const index = selectedCompanies.map((c) => c.name).indexOf(selectedCompany);
    return index < 0 ? false : selectedCompanies[index].roles.includes(value);
  };

  const hasSelectedAnyRole = (company) => {
    const selectedCompanies = flowData.companies ?? [];
    return (
      selectedCompanies.filter((c) => c.name === company && c.roles.length > 0)
        .length > 0
    );
  };

  const hasNotSelectedFromAllCompanies = () => {
    const selectedCompanies = flowData.companies ?? [];
    let ok = false;
    selectedCompanies.forEach((company) => {
      if (!hasSelectedAnyRole(company.name)) {
        ok = true;
      }
    });
    return ok;
  };

  const shouldGreyOut = (company) => {
    const selectedCompanies = flowData.companies ?? [];
    if (selectedCompany === company) {
      return false;
    }
    if (hasSelectedAnyRole(company)) {
      return false;
    }
    return true;
  };

  return (
    <Stack
      spacing={0}
      justifyContent='center'
      alignItems='center'
      textAlign='center'
      width='inherit'
      minHeight='60vh'
      paddingTop='3rem'
    >
      <Stack
        width={{
          xs: '100%',
          sm: '100%',
          md: '90%',
          lg: '100%',
          xl: '100%',
        }}
        spacing={4}
        textAlign='center'
        alignItems='center'
        sx={{
          transition: 'transform 0.5s ease-in-out',
          transform: selectedCompany ? 'translateY(-50%)' : 'none',
        }}
      >
        {selectedCompany === null && (
          <Stack
            spacing={isBelow500 ? 3 : 4}
            textAlign='center'
            alignItems='center'
          >
            <Typography
              variant='body1'
              component='p'
              sx={{
                fontFamily: 'Inter',
                fontSize: {
                  xs: '1.1rem',
                  sm: '1.3rem',
                  md: '1.5rem',
                  lg: '1.5rem',
                },
                width: 'fit-content',
                textAlign: 'center',
                textWrap: 'stable',
              }}
            >
              <b>
                Great! Now please choose your target role at these companies to
                calculate the potential ROI.
              </b>
            </Typography>

            <Typography
              variant='body1'
              component='p'
              sx={{
                fontFamily: 'Inter',
                fontSize: {
                  xs: '1.1rem',
                  sm: '1.2rem',
                  md: '1.2rem',
                  lg: '1.2rem',
                },
                width: 'fit-content',
                textAlign: 'center',
                textWrap: 'stable',
              }}
            >
              Click on the logo{getSelectedCompanyLogos().length > 1 ? 's' : ''}{' '}
              to display the roles
            </Typography>
          </Stack>
        )}

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            ...(isBelowMedium && {
              flexDirection: selectedCompany === null ? 'column' : 'row',
              gap: '2rem',
            }),
          }}
        >
          {getSelectedCompanyLogos().map((company, index) => {
            const isLastLogo = index === getSelectedCompanyLogos().length - 1;
            return (
              <>
                <Grid
                  item
                  xs={4}
                  key={company.name}
                  sx={{
                    height: '100px',
                  }}
                >
                  <Stack
                    spacing={1}
                    alignItems='center'
                    sx={{
                      height: '100px',
                    }}
                  >
                    <CheckCircleIcon
                      color='success'
                      {...(isBelowMedium && {
                        fontSize: 'small',
                      })}
                      sx={{
                        visibility:
                          !hasSelectedAnyRole(company.name) && 'hidden',
                      }}
                    />
                    <CompanyLogo
                      src={company.logo}
                      alt={company.name}
                      onClick={() => handleCompanyClick(company.name)}
                      sx={{
                        filter:
                          shouldGreyOut(company.name) &&
                          'grayscale(100%) opacity(50%)',
                      }}
                    />
                  </Stack>
                </Grid>
              </>
            );
          })}
        </Box>
      </Stack>

      {selectedCompany !== null && (
        <Fade in={selectedCompany !== null}>
          <Stack
            justifyContent='flex-end'
            alignItems='center'
            spacing={4}
            sx={{ width: '100%', textAlign: 'center' }}
          >
            <Grid
              container
              justifyContent='center'
              alignItems='center'
              rowGap={2}
              width='100%'
              flexWrap='wrap'
              columnGap={2}
            >
              {getCompanyRoles(selectedCompany).map((role) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    md={4}
                    lg={3.5}
                    key={role}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      height: '4rem',
                    }}
                  >
                    <Box
                      sx={{
                        border: '1px solid #1976d2',
                        width: '100%',
                        padding: '0.5rem 0.5rem',
                        borderRadius: '0.3rem',
                        color: '#1976d2',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        ...(isSelectedRole(role) && {
                          color: 'white',
                          backgroundColor: '#1976d2',
                        }),
                      }}
                      onClick={() => updateRole(role)}
                    >
                      <p style={{ width: 'fit-content' }}>{role}</p>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>

            <Stack
              spacing={2.5}
              alignItems='center'
            >
              <Button
                disableElevation
                disabled={shouldDisableNext()}
                endIcon={<EastIcon />}
                variant='contained'
                sx={{
                  fontFamily: 'Inter',
                  textTransform: 'none',
                  padding: '0.6rem 1.5rem',
                  background: '#1976d2',
                  width: 'fit-content',
                  '&:hover': {
                    background: '#1976d2',
                  },
                  display: shouldDisableNext() && 'none',
                }}
                onClick={handleNext}
              >
                Calculate ROI
              </Button>

              {getSelectedCompanyLogos().length > 0 &&
                !shouldDisableNext() &&
                hasNotSelectedFromAllCompanies() && (
                  <Typography
                    variant='body1'
                    component='p'
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: {
                        xs: '1rem',
                        sm: '1rem',
                        md: '1.1rem',
                        lg: '1.1rem',
                      },
                      width: 'fit-content',
                      textAlign: 'center',
                      textWrap: 'stable',
                      display: getSelectedCompanyLogos().length <= 1 && 'none',
                    }}
                  >
                    or click on other company logos to discover respective roles
                  </Typography>
                )}
            </Stack>
          </Stack>
        </Fade>
      )}
    </Stack>
  );
};

export default CompanyRoleSelectionScreen;
