import React from 'react';
import Paypal from '../../../assets/paypal.png';
import Uber from '../../../assets/uber.webp';
import Google from '../../../assets/google.webp';
import Meta from '../../../assets/meta.webp';
import Amazon from '../../../assets/amazon.webp';
import Linkedin from '../../../assets/linkedin.webp';
import Flipkart from '../../../assets/flipkart.webp';
import Microsoft from '../../../assets/microsoft.webp';
import Atlassian from '../../../assets/atlassian.webp';

export const companyData = {
  meta: {
    logo: Meta,
    style: {
      width: 'auto',
      height: '28px',
    },
    roles: [
      {
        name: 'E3 - Entry Level',
        minSalary: 2000000,
        maxSalary: 3000000,
      },
      {
        name: 'E4 - Software Engineer III',
        minSalary: 3500000,
        maxSalary: 5000000,
      },
      {
        name: 'E5 - Senior Software Engineer',
        minSalary: 5000000,
        maxSalary: 7000000,
      },
      {
        name: 'E6 - Staff Software Engineer',
        minSalary: 7000000,
        maxSalary: 9000000,
      },
      {
        name: 'E7 - Senior Staff Software Engineer',
        minSalary: 9000000,
        maxSalary: 12000000,
      },
      {
        name: 'E8 - Principal Engineer',
        minSalary: 12000000,
        maxSalary: 15000000,
      },
      {
        name: 'E9 - Distinguished Engineer',
        minSalary: 15000000,
        maxSalary: 20000000,
      },
    ],
  },
  google: {
    logo: Google,
    style: {},
    roles: [
      {
        name: 'L3 - Software Engineer I (Entry-Level)',
        minSalary: 2500000,
        maxSalary: 3500000,
      },
      {
        name: 'L4 - Software Engineer II (Mid-Level)',
        minSalary: 4000000,
        maxSalary: 5500000,
      },
      {
        name: 'L5 - Software Engineer III (Senior)',
        minSalary: 6000000,
        maxSalary: 8000000,
      },
      {
        name: 'L6 - Software Engineer IV (Staff)',
        minSalary: 8000000,
        maxSalary: 11000000,
      },
      {
        name: 'L7 - Software Engineer V (Senior Staff)',
        minSalary: 11000000,
        maxSalary: 15000000,
      },
      {
        name: 'L8 - Software Engineer VI (Principal)',
        minSalary: 15000000,
        maxSalary: 20000000,
      },
    ],
  },
  amazon: {
    logo: Amazon,
    style: {},
    roles: [
      {
        name: 'SDE I',
        minSalary: 1500000,
        maxSalary: 2500000,
      },
      {
        name: 'SDE II',
        minSalary: 2500000,
        maxSalary: 4000000,
      },
      {
        name: 'SDE III',
        minSalary: 4000000,
        maxSalary: 6000000,
      },
      {
        name: 'Principal SDE',
        minSalary: 6000000,
        maxSalary: 8500000,
      },
      {
        name: 'Senior Principal SDE',
        minSalary: 8500000,
        maxSalary: 12000000,
      },
    ],
  },
  linkedin: {
    logo: Linkedin,
    style: {},
    roles: [
      {
        name: 'L3 - Software Engineer (Entry-Level)',
        minSalary: 2000000,
        maxSalary: 3000000,
      },
      {
        name: 'L4 - Software Engineer (Mid-Level)',
        minSalary: 3500000,
        maxSalary: 5000000,
      },
      {
        name: 'L5 - Senior Software Engineer',
        minSalary: 5000000,
        maxSalary: 7000000,
      },
      {
        name: 'L6 - Staff Software Engineer',
        minSalary: 7000000,
        maxSalary: 9000000,
      },
      {
        name: 'L7 - Senior Staff Software Engineer',
        minSalary: 9000000,
        maxSalary: 12000000,
      },
      {
        name: 'L8 - Principal Software Engineer',
        minSalary: 12000000,
        maxSalary: 15000000,
      },
      {
        name: 'L9 - Distinguished Engineer',
        minSalary: 15000000,
        maxSalary: 20000000,
      },
      {
        name: 'L10 - LinkedIn Fellow',
        minSalary: 20000000,
        maxSalary: 25000000,
      },
    ],
  },
  flipkart: {
    logo: Flipkart,
    style: {},
    roles: [
      {
        name: 'SDE I',
        minSalary: 1500000,
        maxSalary: 2200000,
      },
      {
        name: 'SDE II',
        minSalary: 2500000,
        maxSalary: 3500000,
      },
      {
        name: 'SDE III',
        minSalary: 3500000,
        maxSalary: 5000000,
      },
      {
        name: 'Lead SDE',
        minSalary: 5000000,
        maxSalary: 7000000,
      },
      {
        name: 'Staff SDE',
        minSalary: 7000000,
        maxSalary: 9000000,
      },
      {
        name: 'Principal SDE',
        minSalary: 9000000,
        maxSalary: 12000000,
      },
    ],
  },
  uber: {
    logo: Uber,
    style: {},
    roles: [
      {
        name: 'L3 - Software Engineer (Entry-Level)',
        minSalary: 1500000,
        maxSalary: 2000000,
      },
      {
        name: 'L4 - Software Engineer (Mid-Level)',
        minSalary: 2500000,
        maxSalary: 3500000,
      },
      {
        name: 'L5 - Senior Software Engineer',
        minSalary: 4000000,
        maxSalary: 5500000,
      },
      {
        name: 'L6 - Staff Software Engineer',
        minSalary: 5500000,
        maxSalary: 7500000,
      },
      {
        name: 'L7 - Senior Staff Software Engineer',
        minSalary: 7500000,
        maxSalary: 9500000,
      },
      {
        name: 'L8 - Principal Software Engineer',
        minSalary: 9500000,
        maxSalary: 12000000,
      },
      {
        name: 'L9 - Distinguished Engineer',
        minSalary: 12000000,
        maxSalary: 15000000,
      },
    ],
  },
  microsoft: {
    logo: Microsoft,
    style: {},
    roles: [
      {
        name: 'SDE I',
        minSalary: 2500000,
        maxSalary: 4000000,
      },
      {
        name: 'SDE II',
        minSalary: 4000000,
        maxSalary: 6000000,
      },
      {
        name: 'SDE III',
        minSalary: 6000000,
        maxSalary: 8500000,
      },
      {
        name: 'Principal SDE',
        minSalary: 8500000,
        maxSalary: 12000000,
      },
      {
        name: 'Partner SDE',
        minSalary: 10000000,
        maxSalary: 15000000,
      },
    ],
  },
  paypal: {
    logo: Paypal,
    style: {},
    roles: [
      {
        name: 'Software Engineer I (Entry-Level)',
        minSalary: 2200000,
        maxSalary: 3500000,
      },
      {
        name: 'Software Engineer II (Mid-Level)',
        minSalary: 3500000,
        maxSalary: 5000000,
      },
      {
        name: 'Senior Software Engineer',
        minSalary: 5000000,
        maxSalary: 7000000,
      },
      {
        name: 'Staff Software Engineer',
        minSalary: 10000000,
        maxSalary: 15000000,
      },
    ],
  },
  atlassian: {
    logo: Atlassian,
    style: {},
    roles: [
      {
        name: 'Entry-Level Software Engineer',
        minSalary: 2200000,
        maxSalary: 3500000,
      },
      {
        name: 'Mid-Level Software Engineer',
        minSalary: 3500000,
        maxSalary: 5000000,
      },
      {
        name: 'Senior Software Engineer',
        minSalary: 5000000,
        maxSalary: 7000000,
      },
      {
        name: 'Staff Software Engineer',
        minSalary: 7000000,
        maxSalary: 9500000,
      },
      {
        name: 'Principal Software Engineer',
        minSalary: 10000000,
        maxSalary: 12000000,
      },
    ],
  },
};

/**
 * Note: This logic could change if the screens and terminal conditions change.
 * There are two types of terminal screens.
 * Type 1:
 *  This terminal screen can be reached from two screens:
 *    - From 'screen_2' by selecting 'other' as the concern.
 *    - From 'screen_8' by selecing "I'd like to discuss with counsellor".
 *  In this case, terminal screen is 'screen_2b'.
 *
 * Type 2:
 *  This terminal screen can be reached from any screen even from 'screen_2' and 'screen_8'.
 *  In this case, "Release offer letter" screen is the terminal screen (basically, v1 of the fastrack offer letter).
 *
 * We're referring them as terminal screens because from these screens, we can't continue with v4 of the fastrack offer letter.
 *
 * When a user reaches 'screen_2b' and later login again, the user should be kept at 'screen_2b' only (if the offer letter hasn't expired).
 * When a user reached v1 of fastrack offer letter, then user should never be shown v4 again.
 *
 * For now, terminal conditions can be checked by checking the last screen that the user completed.
 *
 * In figma design: 'v1' is also being referred as 'screen_3'.
 * https://www.figma.com/design/kQ0oB7BFAdbmKK1yUzNc1J/APP-TO-TAP-2.0?node-id=0-1&node-type=canvas&t=Jt7yOBQaA92KcskH-0
 */
export const getNextScreen = ({ lastScreen }) => {
  if (lastScreen === 'screen_2b') {
    // keep the user on the same screen.
    return 'screen_2b';
  }

  if (lastScreen === 'screen_8') {
    return 'screen_8';
  }

  if (lastScreen === 'screen_2') {
    return 'screen_4';
  }

  if (lastScreen === 'screen_4') {
    // return 'screen_5';
    return 'screen_6';
  }

  // if (lastScreen === 'screen_5') {
  //   return 'screen_6';
  // }

  if (lastScreen === 'screen_6') {
    return 'screen_7';
  }

  if (lastScreen === 'screen_7') {
    return 'screen_8';
  }

  return 'screen_1';
};
