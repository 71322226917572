// @ts-nocheck
import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import SuccessScreen from '../../components/eligibility-forms/SuccessScreen';
import EfHeader from '../../components/eligibility-forms/EfHeader';
import EligibilityForm from '../../components/eligibility-forms/EligibilityForm';
import ScreeningTest from '../../components/ScreeningTest/ScreeningTest';
import EfConversationFlowLayout from '../../components/eligibility-forms/EfConversationFlowLayout';
import { useEfPipeline } from '../EfPipelineContext/EfPipelineContext';
import LoadingScreen from '../../pages/LoadingScreen';
import { getUserMail } from '../../configs/auth';
import RevisitSOP from 'src/components/eligibility-forms/RevisitSOP';
import FakeResponseAnalyzer from 'src/components/ScreeningTest/FakeResponseAnalyzer';
import FastrackCoupons from 'src/components/FastrackOfferLetter/FastrackCoupons';
import { FastrackPipelineProvider } from '../EfPipelineContext/FastrackPipelineContex';
import FastrackOfferLetterLayout from 'src/components/FastrackOfferLetter/FastrackOfferLetterLayout';

import Calendly from 'src/components/AtomicComponents/CalendlyComp';
import { calendlyURLs } from 'src/apis/urls';
import EfMarketingFlowLayout from 'src/components/eligibility-forms/MarketingFlowQuestions/EfMarketingFlowLayout';
import mktngFlowBg from '../../images/mktngFlowBg.png';


export default function EligibilityFormLayout() {
  const {
    showScreeningTest,
    showFastrackOfferLetter,
    message,
    loading,
    showConversationFlow,
    isSuccess,
    successMessage,
    showFakeResponseAnalyzerForScreeningTest,
    notifyForCoupons,
    showSopRevisit,
  } = useEfPipeline();

  // Temporary flag to test the marketing flow, for now set to false to block the
  // marketing flow view.

  const showMarketingFlowLayout = false;

  const getComponent = () => {
    if (notifyForCoupons) {
      return <FastrackCoupons />;
    }

    if (showFakeResponseAnalyzerForScreeningTest) {
      return (
        <Box
          alignSelf='center'
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'inherit',
            alignItems: 'flex-start',
            paddingTop: '6rem',
            paddingBottom: '2rem',
            paddingLeft: '2rem',
            paddingRight: '2rem',
            rowGap: '2rem',
            overflowY: 'auto',
            overflowX: 'hidden',
            '@media (max-width: 500px)': {
              paddingTop: '1.5rem',
            },
            '@media (min-height: 700px)': {
              paddingTop: '3rem',
            },
          }}
        >
          <FakeResponseAnalyzer />
        </Box>
      );
    }

    if (showFastrackOfferLetter) {
      return (
        <FastrackPipelineProvider>
          <FastrackOfferLetterLayout />
        </FastrackPipelineProvider>
      );
    }

    if (showScreeningTest) {
      return <ScreeningTest />;
    }
    if (showSopRevisit) {
      return <RevisitSOP />;
    }
    if (showConversationFlow) {
      return <EfConversationFlowLayout />;
    }

    if (message !== '') {
      return <GeneralMessage message={message} />;
    }

    if (isSuccess) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px',
            paddingBottom: '3rem',
            paddingTop: '2rem',
          }}
        >
          <SuccessScreen successMessage={successMessage} />;
        </div>
      );
    }

    if (showMarketingFlowLayout) {
      return <EfMarketingFlowLayout />;
    }

    return <EligibilityForm />;
  };

  return (
    <>
      {loading && <LoadingScreen loading={loading} />}
      <Stack
        sx={{
          width: '100vw',
          height: '100vh',
        }}
      >
        <EfHeader />

        <div
          style={{
            flexGrow: 1,
            overflow: 'auto',
            height: 'inherit',
            background:
              showScreeningTest ||
              showFastrackOfferLetter ||
              showFakeResponseAnalyzerForScreeningTest
                ? '#e3f2fd'
                : showMarketingFlowLayout
                ? `url(${mktngFlowBg}) no-repeat center center/cover`
                : 'linear-gradient(45deg, #99ccff, #ffffcc, #ffffff)',
          }}
        >
          {getComponent()}
        </div>
      </Stack>
    </>
  );
}

const GeneralMessage = ({ message }) => {
  const calendlyLinkForCareerCounselling = calendlyURLs().careerCounselling;
  const invitationMessage =
    'You need an invitation to apply to HeyCoach programs. If you are an existing learner, please check your login email id.';
  const isInvitationMessage =
    message.toLowerCase() === invitationMessage.toLowerCase();
  return (
    <Box
      sx={{
        width: isInvitationMessage ? 'auto' : '80%',
        maxWidth: isInvitationMessage ? 'auto' : 800,
        m: 'auto',
        marginTop: isInvitationMessage && '20px',
        p: 4,
      }}
    >
      <Typography
        color='#777'
        variant='h4'
        component='div'
        align='center'
      >
        {isInvitationMessage ? (
          <>
            You need an invitation to apply to HeyCoach programs.
            <br />
            Book a call with the career counselling team.
          </>
        ) : (
          { message }
        )}
      </Typography>
      {isInvitationMessage && (
        <>
          <br />
          <Typography
            color='#777'
            variant='body1'
            component='p'
            align='center'
          >
            {/* You're currently logged in with {getUserMail()}. */}
            <Calendly url={calendlyLinkForCareerCounselling} />
          </Typography>
        </>
      )}
    </Box>
  );
};
