// @ts-nocheck
import React, { useState, createContext, useContext, useEffect } from 'react';

const EfPipelineContext = createContext();
export const useEfPipeline = () => useContext(EfPipelineContext);

export const EfPipelineProvider = (props) => {
  const [loading, setLoading] = useState(false);
  const [formId, setFormId] = useState(null);
  const [showConversationFlow, setShowConversationFlow] = useState(false);
  const [showScreeningTest, setShowScreeningTest] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [message, setMessage] = useState('');
  const [showConfetti, setShowConfetti] = useState(false);
  const [
    showFakeResponseAnalyzerForScreeningTest,
    setShowFakeResponseAnalayerForScreeningTest,
  ] = useState(false);
  const [showFastrackOfferLetter, setShowFastrackOfferLetter] = useState(false);
  const [showSopRevisit, setShowSopRevisit] = useState(false);
  const [sopResponse, setSopResponse] = useState('');
  const [screeningTestResult, setScreeningTestResult] = useState(null);
  const [screeningTestStatus, setScreeningTestStatus] = useState(null);
  const [exclusiveToken, setExclusiveToken] = useState(null);
  const [notifyForCoupons, setNotifyForCoupons] = useState(false);
  const [screeningTestVersion, setScreeningTestVersion] = useState(null);

  useEffect(() => {
    if (!showFakeResponseAnalyzerForScreeningTest) {
      return;
    }

    const id = setTimeout(() => {
      setShowFakeResponseAnalayerForScreeningTest(false);
    }, 23 * 1000); // 23 seconds

    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [showFakeResponseAnalyzerForScreeningTest]);

  useEffect(() => {
    if (!showConfetti) {
      return;
    }

    const id = setTimeout(() => setShowConfetti(false), 5 * 1000);

    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [showConfetti]);

  return (
    <EfPipelineContext.Provider
      value={{
        notifyForCoupons,
        setNotifyForCoupons,
        setScreeningTestStatus,
        screeningTestStatus,
        exclusiveToken,
        setExclusiveToken,
        setScreeningTestResult,
        screeningTestResult,
        setShowFastrackOfferLetter,
        showFastrackOfferLetter,
        showConfetti,
        setShowConfetti,
        showFakeResponseAnalyzerForScreeningTest,
        setShowFakeResponseAnalayerForScreeningTest,
        loading,
        setLoading,
        formId,
        setFormId,
        showConversationFlow,
        setShowConversationFlow,
        showScreeningTest,
        setShowScreeningTest,
        isSuccess,
        setIsSuccess,
        successMessage,
        setSuccessMessage,
        message,
        setMessage,
        showSopRevisit,
        setShowSopRevisit,
        sopResponse,
        setSopResponse,
        screeningTestVersion,
        setScreeningTestVersion,
      }}
    >
      {props.children}
    </EfPipelineContext.Provider>
  );
};
