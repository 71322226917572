// @ts-nocheck
import React, { useEffect, useState, useRef } from 'react';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { styled } from '@mui/material/styles';
import AlarmIcon from '@mui/icons-material/Alarm';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import MobileStepper from '@mui/material/MobileStepper';
import { useEfPipeline } from '../../layouts/EfPipelineContext/EfPipelineContext';
import axios from 'axios';
import {
  getScreeningQuestions,
  getNodeDepth,
  questionTree,
} from './ScreeningQuestions';
import { BASE_URL, getHeaders } from '../../apis/urls';
import moment from 'moment';
import RunningScreeningTestExitPopup from '../ExitIntentPopups/RunningScreeningTestExitPopup';

const Stepper = styled(MobileStepper)({
  alignSelf: 'center',
  padding: 0,
  width: '100%',
  '& .MuiMobileStepper-root': {
    padding: 0,
  },
  '& .MuiMobileStepper-progress': {
    padding: 0,
    height: '10px',
    width: '100%',
    borderRadius: '5px',
  },
});

// This will also change in `RunningScreeningTestExitPopup.jsx`
const TERMINAL_QUESTION = '5';
const TOTAL_QUESTIONS = 5;
const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

const RunningTestLayout = ({
  prevResponse,
  startedAt,
  setExclusiveToken,
  setScreeningTestResult,
  setHasError,
  setErrorMessage,
  trackButtonClick,
}) => {
  const getInitialTime = () => {
    const now = moment();
    const endsAt = startedAt.clone().add(15, 'minutes');
    const difference = endsAt.diff(now, 'seconds');
    return difference;
  };

  const {
    formId,
    setFormId,
    setMessage,
    setShowScreeningTest,
    setShowFastrackOfferLetter,
    setShowFakeResponseAnalayerForScreeningTest,
    screeningTestVersion,
  } = useEfPipeline();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [remainingTime, setRemainingTime] = useState(getInitialTime());
  const [screeningQuestions, setScreeningQuestions] = useState(
    getScreeningQuestions({ version: screeningTestVersion })
  );
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState('1');
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [response, setResponse] = useState({});
  const [shouldAllowNext, setShouldAllowNext] = useState(false);
  const [autoSaving, setAutoSaving] = useState(false);
  const intervalIdRef = useRef(null);
  const [revealAnswerOfQuestionNumber, setRevealAnswerOfQuestionNumber] =
    useState(-1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime((prevTime) => Math.max(0, prevTime - 1));
    }, 1000);

    intervalIdRef.current = intervalId;
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (remainingTime === 0) {
      submitResponse(false, true);
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  }, [remainingTime]);

  useEffect(() => {
    if (prevResponse.length === 0) {
      setCurrentQuestion(screeningQuestions[currentQuestionNumber]);
      return;
    }

    let ptr = currentQuestionNumber;
    let answer = [];
    let maxHeight = 0;
    const tmp = {};
    prevResponse.forEach((resp) => {
      const { qid } = resp;
      const height = getNodeDepth(qid);
      if (height >= maxHeight) {
        maxHeight = height;
        ptr = qid;
        answer = resp.answer;
      }
      tmp[qid] = resp.answer;
    });

    setResponse(tmp);

    const isCorrect = validateSelections(screeningQuestions[ptr], answer);
    const children = questionTree[ptr];
    if (children.length === 0) {
      setCurrentQuestion(null);
    } else {
      const index = Number(isCorrect);
      setCurrentQuestion(screeningQuestions[children[index]]);
      setCurrentQuestionNumber(children[index]);
    }
  }, []);

  const validateSelections = (question, userSelections) => {
    const { options, type } = question;

    if (type === 'text') {
      return true;
    }

    const answers = options
      .filter((opt) => opt.isCorrect)
      .map((opt) => opt.option);

    let foundAnswers = 0;
    answers.forEach((answer) => {
      if (userSelections.includes(answer)) {
        foundAnswers += 1;
      }
    });

    // Temporarily disables 'correct/incorrect' indicator on screening test UI.
    // setRevealAnswerOfQuestionNumber(currentQuestionNumber);
    return foundAnswers === answers.length;
  };

  const getNextQuestion = (isCorrect) => {
    const children = questionTree[currentQuestionNumber];
    if (children.length === 0) {
      setCurrentQuestion(null);
    } else {
      const index = Number(isCorrect);
      setCurrentQuestion(screeningQuestions[children[index]]);
      setCurrentQuestionNumber(children[index]);
    }
    setShouldAllowNext(false);
  };

  const handleNext = () => {
    const isCorrect = validateSelections(
      currentQuestion,
      response[currentQuestionNumber]
    );
    submitResponse(isCorrect);
  };

  const renderQuestion = () => {
    const { getComponent, ...args } = currentQuestion;
    return getComponent({
      ...args,
      response,
      setResponse,
      setShouldAllowNext,
      qid: currentQuestionNumber,
      revealAnswerOfQuestionNumber,
    });
  };

  const submitResponse = async (isCorrect, forceSubmit = false) => {
    setAutoSaving(true);
    try {
      const actionType =
        getNodeDepth(currentQuestionNumber) + 1 === TOTAL_QUESTIONS ||
        forceSubmit
          ? 'submit'
          : 'auto_save';

      const body = {
        response: JSON.stringify(
          Object.keys(response).map((k) => ({ qid: k, answer: response[k] }))
        ),
        action_type: actionType,
      };

      const res = await axios.post(
        `${BASE_URL}/api/eligibility-forms/${formId}/screening-test`,
        body,
        {
          headers: getHeaders(),
        }
      );

      const { token, message, status, show_offer_letter = false } = res.data;
      if (message) {
        if (message.toLowerCase() !== 'ok') {
          setFormId(null);
          setMessage(message);
          setShowScreeningTest(false);
        } else {
          getNextQuestion(isCorrect);
        }
        return;
      }

      if (token) {
        setShowFakeResponseAnalayerForScreeningTest(true);
        if (!show_offer_letter) {
          setExclusiveToken(token);
          setScreeningTestResult('passed');
        } else {
          setShowFastrackOfferLetter(true);
          setShowScreeningTest(false);
        }
      } else {
        setShowFakeResponseAnalayerForScreeningTest(true);
        setScreeningTestResult('failed');
      }
    } catch (err) {
      console.log(err);
      setHasError(true);
      setErrorMessage(
        err.response?.data?.message ?? 'Oop! Something went wrong.'
      );
    } finally {
      setAutoSaving(false);
    }
  };

  const formatTime = () => {
    const hours = Math.floor(remainingTime / 3600);
    const minutes = Math.floor((remainingTime % 3600) / 60);
    const seconds = remainingTime % 60;

    const hh = hours < 10 ? `0${hours}` : `${hours}`;
    const mm = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const ss = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${mm}:${ss}`;
  };

  const getTimerColor = () => {
    // 5 minutes in seconds
    if (remainingTime <= 5 * 60) {
      return 'red';
    }
    return 'black';
  };

  return (
    <Stack
      spacing={2}
      alignItems='center'
      sx={{
        height: '100%',
        width: '65vw',
        [theme.breakpoints.down('md')]: {
          width: '90vw',
        },
        '@media (min-width: 900px) and (max-width: 1200px)': {
          width: '75vw',
        },
        '@media (max-width: 450px)': {
          width: '98vw',
        },
        mb: 'clamp(0, 1rem, 1rem)',
      }}
    >
      <RunningScreeningTestExitPopup
        currentQuestionNumber={currentQuestionNumber}
        trackButtonClick={trackButtonClick}
      />

      <Box
        display='flex'
        justifyContent='space-between'
        width='100%'
        alignItems='center'
      >
        <Typography
          variant='h6'
          component='h6'
          sx={{
            fontFamily: 'Inter',
          }}
        >
          Question {getNodeDepth(currentQuestionNumber) + 1}/{TOTAL_QUESTIONS}
        </Typography>

        <Typography
          variant='h6'
          component='h6'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontFamily: 'Inter',
            [theme.breakpoints.down('sm')]: {
              fontSize: '1rem',
            },
          }}
        >
          <AlarmIcon /> &nbsp;{' '}
          <span style={{ color: getTimerColor() }}>
            Time left: {formatTime()}
          </span>
        </Typography>
      </Box>

      <Stepper
        variant='progress'
        steps={6}
        position='static'
        activeStep={getNodeDepth(currentQuestionNumber) + 1}
        sx={{
          bgcolor: 'transparent',
        }}
      />

      <Stack
        justifyContent='space-between'
        sx={{
          userSelect: 'none',
          backgroundColor: 'white',
          borderRadius: '8px',
          maxHeight: '80vh',
          height: '90vh',
          padding: '3rem',
          width: '100%',
          [theme.breakpoints.down('md')]: {
            padding: '1rem',
            paddingTop: '1.5rem',
          },
        }}
        spacing={2}
      >
        {currentQuestion !== null && renderQuestion()}

        <Box
          width='100%'
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <LoadingButton
            loading={autoSaving}
            variant='contained'
            sx={{
              boxShadow: 'none',
              borderRadius: '0.3rem',
              background: '#1976d2',
              textTransform: 'none',
              ':hover': {
                background: '#1976d2',
              },
            }}
            onClick={handleNext}
            endIcon={<ArrowRightAltIcon />}
            disabled={!shouldAllowNext}
            disableElevation
            size='large'
            {...(isMobile && {
              size: 'medium',
            })}
          >
            {TERMINAL_QUESTION === currentQuestionNumber ? 'Submit' : 'Next'}
          </LoadingButton>
        </Box>
      </Stack>
    </Stack>
  );
};

export default RunningTestLayout;
