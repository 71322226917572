// @ts-nocheck
import {
  Alert,
  Box,
  Grid,
  Snackbar,
  SnackbarContent,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { LoadingButton } from '@material-ui/lab';
import EfPipelineOfferLetter from '../../ScreeningTest/EfPipelineOfferLetter';
import FastrackOfferLetterCountdown from '../FastrackOfferLetterCountdown';
import OfferLetterModal from './OfferLetterModal';
import ConnectingScreen from './ConnectingScreen';
import { useEfPipeline } from 'src/layouts/EfPipelineContext/EfPipelineContext';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';
import FastrackOfferLetterExitPopup from '../FastrackOfferLetterExitPopup';

const SmallScreenOfferLetter = ({ requestExtension, requestCallback }) => {
  const {
    setShowFastrackOfferLetter,
    setMessage,
    olMessage: message,
  } = useEfPipeline();

  const {
    showConnectingScreen,
    setShowConnectingScreen,
    screenType,
    offerLetter,
    activeOLStatus,
    setExpiryExtensionMessage,
    expiryExtensionMessage,
    extensionRequestCount,
  } = useFastrackPipeline();

  const theme = useTheme();
  const isLargerThanMobile = useMediaQuery(theme.breakpoints.up('sm'));
  const isBelow800 = useMediaQuery('(max-width:800px)');
  const [showOfferLetter, setShowOfferLetter] = useState(false);
  const { link } = offerLetter;
  const expiryDate = new Date(offerLetter?.expires_at).getTime();
  const [time, setTime] = useState(expiryDate - new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(expiryDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [expiryDate]);

  const reset = () => {
    setShowFastrackOfferLetter(false);
    setMessage(
      'You need an invitation to apply to HeyCoach programs. If you are an existing learner, please check your login email id.'
    );
  };

  if (time <= 0) {
    reset();
    return;
  }

  return (
    <>
      <FastrackOfferLetterExitPopup setShowOfferLetter={setShowOfferLetter} />

      <Snackbar
        open={expiryExtensionMessage.length > 0 && extensionRequestCount == 1}
        autoHideDuration={6000}
        onClose={() => setExpiryExtensionMessage('')}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert
          onClose={() => setExpiryExtensionMessage('')}
          severity='warning'
          variant='standard'
          sx={{ width: '100%', backgroundColor: '#ffecb3' }}
        >
          {expiryExtensionMessage}
        </Alert>
      </Snackbar>

      <OfferLetterModal
        {...{ showOfferLetter, setShowOfferLetter, offerLetter }}
      />

      {showConnectingScreen && (
        <Grid
          container
          item
          rowSpacing={2}
          height='fit-content'
          justifyContent='space-between'
          sx={{
            paddingBottom: '5rem',
            flexWrap: 'wrap',
            paddingTop: '0.5rem',
            paddingLeft: 'clamp(2rem, 3rem, 4.5rem)',
            paddingRight: 'clamp(2rem, 3rem, 4.5rem)',
          }}
        >
          <ConnectingScreen setShowConnectingScreen={setShowConnectingScreen} />
        </Grid>
      )}

      {!showConnectingScreen && (
        <Stack
          sx={{
            padding: '5rem',
            textAlign: 'center',
            height: 'inherit',
            '@media (max-width: 800px)': {
              paddingLeft: '2rem',
              paddingRight: '2rem',
            },
            '@media (max-width: 600px)': {
              paddingTop: '1rem',
            },
            width: 'inherit',
          }}
          spacing={3}
        >
          <Stack
            direction='row'
            spacing={1}
            justifyContent='center'
          >
            <Box
              sx={{
                fontSize: 'clamp(1.5em, 1.7em, 2em)',
                paddingLeft: 'clamp(0, 6px, 8px)',
                paddingRight: 'clamp(0, 6px, 8px)',
              }}
            >
              🌟
            </Box>

            <Typography
              variant='h2'
              component='h2'
              sx={{
                color: '#1976d2',
                textAlign: 'center',
                fontFamily: 'Inter',
                fontSize: 'clamp(1.6rem, 1.8rem, 2.2rem)',
                [theme.breakpoints.down('sm')]: {
                  fontSize: '1.7rem',
                },
                width: 'fit-content',
              }}
            >
              Congratulations, You've passed!
            </Typography>
            <Box
              sx={{
                fontSize: 'clamp(1.5em, 1.7em, 2em)',
                paddingRight: 'clamp(0, 6px, 8px)',
              }}
            >
              🌟
            </Box>
          </Stack>

          <Typography
            variant='h3'
            component='h3'
            sx={{
              textWrap: 'pretty',
              fontFamily: 'Inter',
              fontWeight: 600,
              [theme.breakpoints.down('sm')]: {
                fontSize: '1.4rem',
              },
            }}
          >
            <span
              style={{
                backgroundImage:
                  'linear-gradient(to left, rgb(0, 154, 240), rgb(251, 199, 4) 120%)',
                '-webkit-text-fill-color': 'transparent',
                backgroundClip: 'text',
              }}
            >
              You're among the top candidates!
            </span>
          </Typography>

          <Typography
            variant='caption'
            component='p'
            textAlign='justify'
            sx={{
              fontSize: '1.4rem',
              textAlign: 'center',
              textWrap: 'stable',
              fontFamily: 'Inter',
              fontWeight: 400,
              '@media (max-width: 600px)': {
                fontSize: '1rem',
              },
            }}
          >
            With a selection rate of just 5%, you've made it through several
            layers of evaluations.
          </Typography>

          <Typography
            variant='caption'
            component='p'
            textAlign='justify'
            sx={{
              fontSize: '1.4rem',
              textAlign: 'center',
              textWrap: 'stable',
              fontFamily: 'Inter',
              fontWeight: 400,
              '@media (max-width: 600px)': {
                fontSize: '1rem',
              },
            }}
          >
            Click the preview below to view your offer letter. It includes
            details about the program and a payment link to secure your seat.
          </Typography>

          <Typography
            variant='h6'
            component='h6'
            sx={{
              fontSize: '1.4rem',
              textAlign: 'center',
              textWrap: 'balance',
              fontFamily: 'Inter',
              fontWeight: 'bold',
              '@media (max-width: 600px)': {
                fontSize: '1rem',
              },
            }}
          >
            The offer letter expires in 30 minutes.
          </Typography>

          <Stack
            sx={{
              position: 'relative',
            }}
          >
            <Box
              height='400px'
              width={{
                xs: '92%',
                sm: '65%',
                md: '50%',
              }}
              alignSelf='center'
              sx={{
                filter: 'blur(5px)',
                position: 'relative',
              }}
            >
              <EfPipelineOfferLetter {...offerLetter} />
            </Box>
            <Button
              variant='contained'
              size={isLargerThanMobile ? 'large' : 'small'}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                padding: isLargerThanMobile ? '0.6rem' : '0.5rem',
                fontSize: 'clamp(0.5rem, 0.8rem, 1rem)',
                width: '150px',
              }}
              onClick={(e) => setShowOfferLetter(true)}
            >
              <RemoveRedEyeIcon fontSize='small' /> &nbsp; View Offer Letter
            </Button>
          </Stack>

          <Box
            sx={{
              display: 'flex',
              alignSelf: 'center',
              padding: '0.2em 5px',
              border: '1px solid #1976d2',
              width: 'fit-content',
            }}
          >
            <Typography
              variant='h6'
              component='p'
              sx={{
                fontSize: 'clamp(0.9rem, 0.9rem, 1.4rem)',
                color: '#1976d2',
                fontFamily: 'Inter',
                ':hover': {
                  cursor: 'pointer',
                },
                textDecorationSkipInk: 'none',
              }}
              onClick={(e) => {
                setShowConnectingScreen(true);
                requestCallback();
              }}
            >
              Need more info? Request a callback
            </Typography>
          </Box>
        </Stack>
      )}

      <Stack
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100vw',
          left: 0,
          padding: '1.3rem',
          boxShadow: '0px -2px 4px 0px #00000040',
          backdropFilter: 'blur(25px)',
          background: '#e5f5fd1a',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        alignItems='center'
      >
        <Stack
          direction={isBelow800 ? 'column' : 'row'}
          justifyContent='center'
          alignItems='center'
          spacing={isBelow800 ? 1.5 : 5}
        >
          <Stack
            direction='row'
            spacing={2}
            alignItems='center'
          >
            <Typography
              variant='h6'
              component='h6'
              sx={{
                fontFamily: 'Inter',
                fontWeight: 400,
              }}
            >
              The offer letter expires in:
            </Typography>
            <FastrackOfferLetterCountdown
              time={time}
              style={{ background: '#ffeb3b' }}
            />
          </Stack>

          <Stack
            direction='row'
            spacing={1}
          >
            {extensionRequestCount <= 1 && screenType === 'screen_1' && (
              <Button
                variant='contained'
                sx={{
                  padding: '0.7rem 1.3rem',
                  fontSize: '0.9rem',
                  '@media (max-width: 400px)': {
                    fontSize: '0.8rem',
                    padding: '0.8rem 1rem',
                  },
                  background: 'white',
                  color: '#1976d2',
                  ':hover': {
                    background: 'white',
                    color: '#1976d2',
                  },
                }}
                onClick={(e) => {
                  console.log('requested callback');
                  requestExtension();
                }}
                startIcon={
                  <AccessAlarmIcon
                    fontSize='small'
                    sx={{ color: '#1976d2' }}
                  />
                }
              >
                I need more time
              </Button>
            )}

            <Tooltip title={activeOLStatus === 'processing' ? message : ''}>
              <span>
                <LoadingButton
                  loading={activeOLStatus === 'processing'}
                  disabled={activeOLStatus === 'processing'}
                  variant='contained'
                  href={link}
                  sx={{
                    color: '#FFFFFF',
                    padding: '0.7rem 1.3rem',
                    fontSize: '0.9rem',
                    '@media (max-width: 400px)': {
                      fontSize: '0.8rem',
                      padding: '0.8rem 1rem',
                    },
                    background:
                      'linear-gradient(to left, rgb(0, 154, 240), rgb(251, 199, 4) 120%)',
                  }}
                >
                  Secure your seat
                </LoadingButton>
              </span>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default SmallScreenOfferLetter;
