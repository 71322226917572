// @ts-nocheck
import {
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import React from 'react';
import FastrackOfferLetterCountdown from '../FastrackOfferLetterCountdown';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';

const FastrackModePaymentCard = ({ time, setShowOfferLetter }) => {
  const { trackButtonClick } = useFastrackPipeline();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const releaseOfferLetter = () => {
    setShowOfferLetter(true);
    trackButtonClick({
      source: 'fastrack_ol_screen',
      eventName: 'release_offer_letter',
    });
  };

  return (
    <Stack
      spacing={1.5}
      sx={{
        width: {
          xs: '20rem',
          sm: '22rem',
          md: '23rem',
          lg: '36%',
          xl: '32%',
        },
        height: '27rem',
        maxHeight: 'fit-content',
        border: '1px solid #bdbdbd',
        borderRadius: '0.5rem',
        padding: '1.3rem',
        display: 'flex',
        backgroundImage: 'linear-gradient(#A9E5E6, #FFFFDD)',
      }}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography
          variant='body1'
          component='p'
          sx={{
            fontFamily: 'Inter',
            fontWeight: 'bold',
            fontSize: '1.5rem',
          }}
        >
          Fastrack Mode
        </Typography>

        <Chip
          size='small'
          label='Best Value'
          sx={{
            fontFamily: 'Inter',
            padding: '0.8rem 0.4rem',
            fontWeight: 500,
            background: 'white',
          }}
        />
      </Stack>

      <Typography
        variant='body2'
        component='p'
        sx={{
          fontFamily: 'Inter',
          fontWeight: 500,
          fontSize: '0.8rem',
        }}
      >
        Accelerate your application process by skipping the waitlist for
        screening interview.
      </Typography>

      <Box
        display='flex'
        gap='8px'
      >
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '1.5rem',
          }}
        >
          &#8377;
        </Typography>
        <Typography
          variant='h2'
          component='h2'
          sx={{
            fontFamily: 'Inter',
            fontWeight: 'bolder',
            paddingTop: 0,
            lineHeight: 'normal',
          }}
        >
          10,000
        </Typography>

        <Stack
          direction='row'
          spacing={0.5}
        >
          <Typography
            variant={isMobile ? 'caption' : 'body2'}
            component='p'
            sx={{
              fontFamily: 'Inter',
              alignSelf: 'center',
              fontWeight: 600,
              fontSize: '1rem',
            }}
          >
            +
          </Typography>
          <Typography
            variant={isMobile ? 'caption' : 'body2'}
            component='p'
            sx={{
              fontFamily: 'Inter',
              alignSelf: 'center',
              fontWeight: 600,
              fontSize: '0.88rem',
              lineHeight: '1.5ch',
            }}
          >
            additional benefits
          </Typography>
        </Stack>
      </Box>

      <Divider
        orientation='horizontal'
        flexItem
        sx={{ borderBottomWidth: 1.5 }}
      />

      <Stack
        spacing={1.5}
        pt='0.6rem'
      >
        <Typography
          variant='body1'
          component='p'
          sx={{
            fontFamily: 'Inter',
            display: 'flex',
            gap: '0.8rem',
            fontWeight: 600,
          }}
        >
          <CheckCircleIcon color='success' /> Exclusive coupons
        </Typography>

        <Typography
          variant='body1'
          component='p'
          sx={{
            fontFamily: 'Inter',
            display: 'flex',
            gap: '0.8rem',
            fontWeight: 600,
          }}
        >
          <CheckCircleIcon color='success' /> Access to language bible
        </Typography>
      </Stack>

      <Stack
        spacing={1.5}
        paddingTop='1rem'
      >
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
          gap='0.6rem'
        >
          <Typography
            variant='body1'
            component='p'
            sx={{
              fontFamily: 'Inter',
              fontWeight: 'normal',
            }}
          >
            The offer expires in:
          </Typography>
          <FastrackOfferLetterCountdown time={time} />
        </Stack>

        <Button
          endIcon={<ArrowRightAltIcon fontSize='large' />}
          sx={{
            fontFamily: 'Inter',
            backgroundImage:
              'linear-gradient(to left, rgb(0, 154, 240), rgb(251, 199, 4) 120%)',
            color: 'white',
            padding: '0.8rem 0.5rem',
            width: '80%',
            alignSelf: 'center',
          }}
          onClick={releaseOfferLetter}
        >
          Release my offer letter
        </Button>
      </Stack>
    </Stack>
  );
};

export default FastrackModePaymentCard;
