// @ts-nocheck
import React, { useRef, useState, useEffect } from 'react';
import {
  Box,
  Stack,
  useMediaQuery,
  useTheme,
  Grid,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import PaymentModesScreen from './PaymentModesScreen';

const steps = ['Application form', 'Screening test', 'Offer letter'];

const Screen3 = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTab = useMediaQuery('(max-width: 1200px)');

  return (
    <>
      <Box
        alignSelf='center'
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#e3f2fd',
          paddingTop: '6rem',
          paddingBottom: '2rem',
          paddingLeft: '2rem',
          paddingRight: '2rem',
          overflowY: 'auto',
          overflowX: 'hidden',
          '@media (max-width: 500px)': {
            paddingTop: '1.5rem',
            paddingLeft: '2rem',
          },
          '@media (min-height: 700px)': {
            paddingTop: '3rem',
          },
        }}
      >
        <Grid
          container
          spacing={4}
          sx={{
            marginLeft: 0,
            backgroundColor: 'white',
            borderRadius: '12px',
            width: {
              xs: '80vw',
              sm: '80vw',
              md: '84vw',
              lg: '80vw',
              xl: '80vw',
            },
            height: 'fit-content',
            '@media (max-width: 1200px)': {
              height: 'fit-content',
              width: {
                xs: '95vw',
                sm: '85vw',
                md: '80vw',
              },
            },
            paddingTop: '3rem',
            paddingBottom: '3rem',
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ width: '90%', maxWidth: '90%' }}>
              <Stepper
                activeStep={2}
                alternativeLabel={isMobile}
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel
                      sx={{
                        fontFamily: 'Inter',
                        color: '#1976d2',
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            container
            direction='column'
            height='100%'
            style={{
              paddingLeft: 0,
            }}
          >
            <PaymentModesScreen />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Screen3;
