// @ts-nocheck
import {
  Typography,
  Stack,
  Box,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import EastIcon from '@mui/icons-material/East';

import React from 'react';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';
import { companyData } from '.';

const ShowPercentageGainV2 = ({
  flowData,
  setFlowData,
  handleReadyToEnroll,
  setShowTerminalScreen,
  saveFlowData,
}) => {
  const { screenSource, setScreenSource, trackButtonClick } =
    useFastrackPipeline();
  const theme = useTheme();
  const isBelow800 = useMediaQuery('(max-width: 800px)');

  const handleDiscussWithCounsellor = () => {
    trackButtonClick({
      source: 'screen_8',
      eventName: 'discuss_with_counsellor',
    });
    saveFlowData({
      screen: 'screen_8',
      response: { is_ready_to_enroll: false },
    });
    setScreenSource('screen_2b');
  };

  const getAverageSalary = () => {
    let min = 0,
      max = 0,
      total = 0;
    const { companies } = flowData;

    companies.forEach((company) => {
      if (company.roles?.length === 0) {
        return;
      }

      const roles = companyData[company.name].roles.filter((role) =>
        company.roles.includes(role.name)
      );
      total += roles.length;

      roles.forEach((role) => {
        min += role.minSalary;
        max += role.maxSalary;
      });
    });

    if (total === 0) {
      return {
        min: 60,
        max: 70,
      };
    }

    return {
      min: Math.floor(min / total / 100000),
      max: Math.floor(max / total / 100000),
    };
  };

  const getAverageFixedHike = () => {
    let min = flowData?.ctc + flowData?.ctc * 0.5;
    let max = flowData?.ctc + flowData?.ctc * 0.6;

    if (min >= 100) {
      min = (min / 100).toFixed(1);
      max = (max / 100).toFixed(1);

      if (min == max) {
        return min + ' Cr+';
      }
      
      return min + '-' + max + ' Cr';
    }

    min = Math.floor(min);
    max = Math.floor(max);
    
    return min + '-' + max + ' LPA';
  };

  return (
    <Stack
      spacing={5}
      alignItems='center'
      textAlign='center'
      width='inherit'
    >
      <Stack
        width={{
          xs: '100%',
          sm: '100%',
          md: '90%',
          lg: '70%',
          xl: '50%',
        }}
        textAlign='center'
        alignItems='center'
      >
        <Typography
          variant='body1'
          component='h3'
          sx={{
            color: '#1976d2',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: {
              xs: '1rem',
              sm: '1.2rem',
              md: '1.3rem',
              lg: '1.4rem',
            },
            width: 'fit-content',
            textAlign: 'center',
            textWrap: 'stable',
          }}
        >
          Uhh oh! You are targeting a role with average salary range of{' '}
          <span
            style={{
              textDecoration: 'underline dashed #1976d2 2.25px',
              textUnderlineOffset: '5px',
              fontFamily: 'Inter',
            }}
          >
            ₹{Object.values(getAverageSalary()).join('-')}
          </span>{' '}
          LPA while your current salary is{' '}
          <span
            style={{
              textDecoration: 'underline dashed #1976d2 2.25px',
              textUnderlineOffset: '5px',
              fontFamily: 'Inter',
            }}
          >
            ₹ {flowData?.ctc} LPA
          </span>
        </Typography>
      </Stack>
      <Stack
        width={{
          xs: '100%',
          sm: '100%',
          md: '90%',
          lg: '73%',
          xl: '50%',
        }}
        textAlign='center'
        alignItems='center'
      >
        <Typography
          variant='body1'
          component='p'
          sx={{
            fontFamily: 'Inter',
            fontSize: {
              xs: '1rem',
              sm: '1.2rem',
              md: '1.3rem',
              lg: '1.3rem',
            },
            width: 'fit-content',
            textAlign: 'center',
            textWrap: 'pretty',
          }}
        >
          Instead you should be targeting roles that get you into an average
          salary range of{' '}
          <span
            style={{
              textDecoration: 'underline dashed #1976d2 2.25px',
              textUnderlineOffset: '5px',
              fontFamily: 'Inter',
            }}
          >
            <b>₹{getAverageFixedHike()}</b>
          </span>
        </Typography>
      </Stack>
      <Stack
        width={{
          xs: '100%',
          sm: '100%',
          md: '90%',
          lg: '70%',
          xl: '50%',
        }}
        textAlign='center'
        alignItems='center'
      >
        <Typography
          variant='body1'
          component='p'
          sx={{
            fontFamily: 'Inter',
            fontSize: {
              xs: '1rem',
              sm: '1.2rem',
              md: '1.3rem',
              lg: '1.3rem',
            },
            width: 'fit-content',
            textAlign: 'center',
            textWrap: 'pretty',
          }}
        >
          Think about it—investing <b>₹1.58 Lakh</b> could open doors to a
          career that offers you <b>50%+ gain every year</b>. FYI, top mutual
          funds give 15% once a year.
        </Typography>
      </Stack>

      <Typography
        variant='body1'
        component='p'
        sx={{
          fontFamily: 'Inter',
          fontSize: {
            xs: '1rem',
            sm: '1.2rem',
            md: '1.3rem',
            lg: '1.3rem',
          },
          width: 'fit-content',
          textAlign: 'center',
          textWrap: 'stable',
        }}
      >
        <b>
          Don't you think this could be one of the best investments of your
          life?
        </b>
      </Typography>

      <Stack
        direction={isBelow800 ? 'column' : 'row'}
        paddingTop={isBelow800 ? '1.5rem' : '3rem'}
        sx={{
          '@media (max-width: 800px)': {
            alignItems: 'center',
          },
        }}
        spacing={2}
        justifyContent='space-between'
        width={{
          xs: '100%',
          sm: '100%',
          md: '80%',
          lg: '60%',
          xl: '50%',
        }}
      >
        <Button
          endIcon={<EastIcon />}
          variant='outlined'
          sx={{
            fontFamily: 'Inter',
            textTransform: 'none',
            padding: '0.6rem 1rem',
            color: '#1976d2',
            '@media (max-width: 800px)': {
              width: 'fit-content',
            },
            borderColor: '#1976d2',
            '&:hover': {
              color: '#1976d2',
              borderColor: '#1976d2',
            },
          }}
          onClick={handleDiscussWithCounsellor}
        >
          I'd like to discuss with counsellor
        </Button>

        <Button
          variant='contained'
          sx={{
            fontFamily: 'Inter',
            textTransform: 'none',
            padding: '0.6rem 1.8rem',
            '@media (max-width: 800px)': {
              padding: '0.6rem 3rem',
            },
            '@media (max-width: 400px)': {
              padding: '0.6rem 1.8rem',
            },
            background:
              'linear-gradient(to right, rgb(0, 154, 240), rgb(251, 199, 4))',
          }}
          onClick={(e) => {
            saveFlowData({
              screen: 'screen_8',
              response: { is_ready_to_enroll: true },
            });
            handleReadyToEnroll({ source: screenSource });
          }}
          endIcon={<EastIcon />}
        >
          Okay, I'm ready to enroll
        </Button>
      </Stack>
    </Stack>
  );
};

export default ShowPercentageGainV2;
