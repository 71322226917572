// @ts-nocheck
import {
  Alert,
  Box,
  Grid,
  Snackbar,
  SnackbarContent,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import EastIcon from '@mui/icons-material/East';
import { LoadingButton } from '@material-ui/lab';
import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import FastrackOfferLetterCountdown from '../FastrackOfferLetterCountdown';
import OfferLetterModal from '../OfferLetterModal';
import GreatFitForCourse from './GreatFitForCourse';
import NotYetReadyCard from './NotYetReadyCard';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';
import { useEfPipeline } from 'src/layouts/EfPipelineContext/EfPipelineContext';
import FastrackOfferLetterExitPopup from '../FastrackOfferLetterExitPopup';
import SelectConcernScreen from './SelectConcernScreen';
import SharingConcernTerminationScreen from './SharingConcernTerminationScreen';
import CompanisListScreen from './CompanisListScreen';
import SalaryROICalculatorIntro from './SalaryROICalculatorIntro';
import SelectYourCTC from './SelectYourCTC';
import ShowPercentageGain from './ShowPercentageGain';
import CompanyRoleSelectionScreen from './CompanyRoleSelectionScreen';

const FastrackOfferLetterNotYetReadyScreen = ({
  flowData,
  setFlowData,
  saveFlowData,
}) => {
  const { offerLetter, trackButtonClick, releaseOfferLetter, screenSource } =
    useFastrackPipeline();
  const { setShowFastrackOfferLetter, setMessage } = useEfPipeline();

  const theme = useTheme();
  const { link } = offerLetter;
  const expiryDate = new Date(offerLetter?.expires_at).getTime();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showOfferLetter, setShowOfferLetter] = useState(false);
  const [time, setTime] = useState(expiryDate - new Date().getTime());
  const [showTerminalScreen, setShowTerminalScreen] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(expiryDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [expiryDate]);

  const reset = () => {
    setShowFastrackOfferLetter(false);
    setMessage(
      'You need an invitation to apply to HeyCoach programs. If you are an existing learner, please check your login email id.'
    );
  };

  if (time <= 0) {
    reset();
    return;
  }

  const handleReadyToEnroll = ({ source }) => {
    releaseOfferLetter({ source });
  };

  const getComponent = () => {
    if (screenSource.startsWith('screen_4')) {
      return (
        <CompanisListScreen
          flowData={flowData}
          setFlowData={setFlowData}
          saveFlowData={saveFlowData}
        />
      );
    }

    // if (screenSource.startsWith('screen_5')) {
    //   return (
    //     <SalaryROICalculatorIntro
    //       flowData={flowData}
    //       setFlowData={setFlowData}
    //       saveFlowData={saveFlowData}
    //     />
    //   );
    // }

    if (screenSource.startsWith('screen_6')) {
      return (
        <CompanyRoleSelectionScreen
          flowData={flowData}
          setFlowData={setFlowData}
          saveFlowData={saveFlowData}
        />
      );
    }

    if (screenSource.startsWith('screen_7')) {
      return (
        <SelectYourCTC
          flowData={flowData}
          setFlowData={setFlowData}
          saveFlowData={saveFlowData}
        />
      );
    }

    if (screenSource.startsWith('screen_8')) {
      return (
        <ShowPercentageGain
          flowData={flowData}
          setFlowData={setFlowData}
          handleReadyToEnroll={handleReadyToEnroll}
          setShowTerminalScreen={setShowTerminalScreen}
          saveFlowData={saveFlowData}
        />
      );
    }

    if (screenSource === 'screen_2b') {
      return <SharingConcernTerminationScreen />;
    }

    if (screenSource === 'screen_2') {
      return (
        <SelectConcernScreen
          setShowTerminalScreen={setShowTerminalScreen}
          saveFlowData={saveFlowData}
        />
      );
    }

    return (
      <>
        <Grid
          item
          xs={12}
        >
          <Stack
            spacing={isSmall ? 2 : 1.3}
            alignItems='center'
          >
            <Typography
              variant='h3'
              component='h3'
              sx={{
                color: '#1976d2',
                fontFamily: 'Inter',
                fontWeight: 'bolder',
                fontSize: {
                  xs: '1.8rem',
                  sm: '2.1rem',
                  md: '2.1rem',
                  lg: '2.1rem',
                },
                width: 'fit-content',
                textAlign: 'center',
              }}
            >
              You're among the top candidates to receive an offer letter
            </Typography>

            <Typography
              variant='body1'
              component='p'
              sx={{
                textAlign: 'center',
                fontFamily: 'Inter',
                fontWeight: 'normal',
                fontSize: {
                  xs: '1.2rem',
                  sm: '1.4rem',
                  md: '1.4rem',
                  lg: '1.4rem',
                },
              }}
            >
              With a selection rate of just 5%, you've made it through several
              layers of evaluations.
            </Typography>
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Stack
            direction={isSmall ? 'column-reverse' : 'row'}
            justifyContent='center'
            alignItems='center'
            spacing={4}
            sx={{
              paddingTop: '1rem',
            }}
          >
            <NotYetReadyCard />
            <GreatFitForCourse
              time={time}
              handleReadyToEnroll={handleReadyToEnroll}
            />
          </Stack>
        </Grid>
      </>
    );
  };

  const shouldShowReadyToEnrollButton = () => {
    const allowedScreens = [
      'screen_2',
      'screen_2a',
      'screen_4',
      'screen_5',
      'screen_6',
      'screen_7',
    ];

    return allowedScreens.includes(screenSource);
  };

  return (
    <>
      <FastrackOfferLetterExitPopup setShowOfferLetter={setShowOfferLetter} />

      {/* Note: Commented because we don't want to show offer letter in modal. */}
      {/* <OfferLetterModal
        {...{
          showOfferLetter,
          setShowOfferLetter,
          time,
          link,
        }}
      /> */}

      <Grid
        container
        item
        rowSpacing={4}
        height='inherit'
        justifyContent='space-between'
        sx={{
          flexWrap: 'wrap',
          paddingTop: '0.5rem',
          paddingLeft: 'clamp(2rem, 3rem, 4.5rem)',
          paddingRight: 'clamp(2rem, 3rem, 4.5rem)',
        }}
      >
        {getComponent()}
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-end',
          paddingTop: 0,
          height: 'fit-content',
        }}
      >
        <Stack
          spacing={isMobile ? 2 : 4}
          direction={isMobile ? 'column' : 'row'}
          alignItems='center'
          justifyContent='center'
          sx={{
            width: {
              xs: '95%',
              sm: '95%',
              md: '90%',
              lg: '70%',
              xl: '70%',
            },
            height: 'fit-content',
            padding: 'clamp(0.5rem, 0.8rem, 1rem)',
            borderRadius: '36px',
            background: '#e3f2fd',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 5px 5px 0px',
          }}
        >
          <Stack
            direction='row'
            spacing={2}
            alignItems='center'
          >
            <Typography
              variant='h6'
              component='h6'
              sx={{
                fontFamily: 'Inter',
                fontWeight: 500,
              }}
            >
              {!isSmall ? 'This offer letter expires in: ' : 'Expires in: '}
            </Typography>
            <FastrackOfferLetterCountdown
              time={time}
              style={{ background: '#ffeb3b' }}
            />
          </Stack>

          {shouldShowReadyToEnrollButton() && (
            <Button
              variant='contained'
              sx={{
                fontFamily: 'Inter',
                textTransform: 'none',
                padding: '0.8rem 1.1rem',
                background:
                  'linear-gradient(to right, rgb(0, 154, 240), rgb(251, 199, 4))',
              }}
              onClick={(e) => handleReadyToEnroll({ source: screenSource })}
              endIcon={<EastIcon />}
            >
              I'm ready to enroll
            </Button>
          )}
        </Stack>
      </Grid>
    </>
  );
};

export default FastrackOfferLetterNotYetReadyScreen;
